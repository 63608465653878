import React from "react"
import { graphql } from "gatsby"

// components
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Landing from "../components/building-commissioning/landing"
import Steps from "../components/building-commissioning/steps"
import CaseStudy from "../components/case-study"

// style
import "../styles/landing.css"

const BuildingCommissioning = ({ data }) => {
  return (
    <Layout
      headerLang={data?.langQuery?.siteMetadata?.en?.header}
      footerLang={data?.langQuery?.siteMetadata?.en?.footer}
      langType="en"
    >
      <SEO
        title="Building Commissioning"
        description="EEE performs Building Commisioning Projects timely, gloabaly, and remotely."
      />
      <Landing
        fluid={data?.headerImage?.childImageSharp?.fluid}
        lang={data?.langQuery?.siteMetadata?.en?.commissioning?.landing}
        langType="en"
      />
      <div id="more-info">
        <Steps
          designSRC={data?.designSRC?.childImageSharp?.fluid}
          installSRC={data?.installSRC?.childImageSharp?.fluid}
          testSRC={data?.testSRC?.childImageSharp?.fluid}
          acceptSRC={data?.acceptSRC?.childImageSharp?.fluid}
          lang={data?.langQuery?.siteMetadata?.en?.commissioning?.approach}
        />
        <CaseStudy data={data} />
      </div>
    </Layout>
  )
}

export default BuildingCommissioning

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "commissioning.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    designSRC: file(
      relativePath: { eq: "priscilla-du-preez-XkKCui44iM0-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    installSRC: file(
      relativePath: { eq: "bosco-shots-IX10axbDIpk-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testSRC: file(
      relativePath: { eq: "thisisengineering-raeng-grDTSAnM8b4-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    acceptSRC: file(
      relativePath: { eq: "sincerely-media-EtyBBUByPSQ-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    langQuery: site {
      siteMetadata {
        en {
          header {
            commissioning
            modeling
            retrofits
            cfd
            projects
            contact
          }
          footer {
            contact
            navItems
          }
          commissioning {
            landing {
              headerTitle
              headerBody
              subSectionTitle
              subSectionBody
            }
            approach {
              title
              body
              phases
              design {
                title
                body
                subTitle
                involvement
              }
              installation {
                title
                body
                subTitle
                involvement
              }
              testing {
                title
                body
                subTitle
                involvement
              }
              acceptance {
                title
                body
                subTitle
                involvement
              }
            }
          }
        }
      }
    }
    allContentfulFeaturedProject(
      filter: {
        service: {
          name: { eq: "Building Commissioning" }
          node_locale: { eq: "en-US" }
        }
      }
    ) {
      edges {
        node {
          id
          name
          summary {
            summary
          }
          previewImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            description
          }
        }
      }
    }
  }
`
